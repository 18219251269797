export function useGetTimeAgo(timestamp: number) {
  const intervals = [
    { label: 'yr', seconds: 31536000 },
    { label: 'mo', seconds: 2592000 },
    { label: 'd', seconds: 86400 },
  ]

  const currentTime = Math.floor(new Date().getTime() / 1000)
  const timeElapsed = currentTime - Math.floor(timestamp / 1000)

  const interval = intervals.find(i => timeElapsed >= i.seconds)
  if (!interval)
    return '1d ago'

  const value = Math.floor(timeElapsed / interval.seconds)
  return `${value}${interval.label} ago`
}
